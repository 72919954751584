import Vue from 'vue'

const FlavorService = {
  all (param) {
    return Vue.http.get('flavor/index', param)
  },

  delete (id) {
    return Vue.http.delete('flavor/delete', { id: id })
  },

  create (store = null) {
    const endpoint = 'flavor/create'

    if (store === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, store)
  },

  edit (id, user = null) {
    if (user === null) {
      return Vue.http.get('flavor/update', { id: id })
    }

    return Vue.http.put('flavor/update', user, { id: id })
  }
}

export default FlavorService
