<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')">
    <el-form-item
      label="规格组名称"
      prop="flavorName"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.flavorName"
        ></el-input>
      </el-col>
    </el-form-item>
    <div style="display:flex;">
      <el-form-item
        label="必选"
        prop="isRequired"
      >
        <el-switch
          v-model="formModel.isRequired"
          inactive-color="#ddd"
          :active-text="formModel.isRequired == 1?'是':'否'"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="参与打折"
        prop="isSales"
      >
        <el-switch
          v-model="formModel.isSales"
          inactive-color="#ddd"
          :active-text="formModel.isSales == 1?'是':'否'"
        >
        </el-switch>
      </el-form-item>
    </div>
    <el-form-item
      label="单选"
      prop="choiceMode"
    >
      <el-switch
        v-model="formModel.choiceMode"
        inactive-color="#ddd"
        :active-text="formModel.choiceMode == 1?'是':'否'"
      >
      </el-switch>
    </el-form-item>
    <!-- 选项方案 -->
    <el-form-item>
      <el-col :md="24">
        <el-button
          @click="addMasterSku()"
          class="el-table-add-row"
        >+添加选项</el-button>
      </el-col>
      <el-col :md="24">
        <el-table
          size="mini"
          :data="skuList.data"
          border
          style="width: 100%"
          highlight-current-row
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            v-for="(v,i) in skuList.columns"
            :key="i"
            :prop="v.field"
            :label="v.title"
            :width="v.width"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">
                <el-button
                  v-if="v.field === 'stock'"
                  type="text"
                  @click="openModel(scope.row,scope.$index)"
                >
                {{(scope.row.is_ks_stock === true)?'不计库存':scope.row[v.field]}}</el-button>
                <el-input
                  size="mini"
                  placeholder="请输入内容"
                  v-model="scope.row[v.field]"
                  v-else
                >
                </el-input>
              </span>
              <span v-else-if="v.field === 'stock'">{{(skuList.sel[scope.$index].is_ks_stock === true)?'不计库存':skuList.sel[scope.$index][v.field]}}</span>
              <span v-else>{{skuList.sel[scope.$index][v.field]}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
          >
            <template slot-scope="scope">
              <span
                class="el-tag el-tag--mini"
                style="cursor: pointer;"
                @click="pwdChange(scope.row,scope.$index,true)"
              >
                {{scope.row.isSet?'保存':"修改"}}
              </span>
              <span
                v-if="!scope.row.isSet"
                class="el-tag el-tag--danger el-tag--mini"
                style="cursor: pointer;margin-left:20px;"
                @click="deleteSku(scope.row,scope.$index)"
              >
                删除
              </span>
              <span
                v-else
                class="el-tag  el-tag--info  el-tag--mini"
                style="cursor: pointer;margin-left:20px;"
                @click="pwdChange(scope.row,scope.$index,false)"
              >
                取消
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-form-item>
    <!-- 提交 -->
    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
    <!-- 弹出窗口内容 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="400"
    >
      <div class="stock-box">
        <div class="stock-item">
          当前库存
          <el-input type="number" :min="0" v-model.trim="stock.stock"/>
        </div>
        <div class="stock-item">
          每日最大数量
          <el-input type="number"  :min="0" v-model.trim="stock.max_stock"/>
        </div>
        <div class="stock-item">
          <el-checkbox v-model="stock.is_refresh">每日刷新重置数量</el-checkbox>
        </div>
        <div class="stock-item">
          <el-checkbox v-model="stock.is_ks_stock">不计库存</el-checkbox>
        </div>
        <el-button  @click="stockSave()">确定</el-button>
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
export default {
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    flavor: {
      type: Object,
      default: null
    }
  },
  data () {
    const _skuList = {
      sel: [],
      columns: [
        { field: 'sku_name', title: '方案名称' },
        { field: 'sku_price', title: '价格' },
        { field: 'sort', title: '位置' },
        { field: 'stock', title: '库存' }
      ],
      data: []
    }
    return {
      dialogVisible: false,
      checkList: [],
      submitLoading: false,
      formRules: {
        flavorName: [
          {
            required: true,
            message: '请输入规格名称',
            trigger: 'blur'
          }
        ]
      },
      formModel: null,
      skuList: _skuList,
      nowSelStockId: 0,
      stock: {
        stock: 0,
        max_stock: 0,
        is_refresh: false,
        is_ks_stock: true
      }
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.flavor)
    this.skuList.data = Object.assign(this.flavor.sku)
    this.skuList.sel = Object.assign(this.flavor.sku)
    this.formModel.skuData = Object.assign(this.skuList)
    this.formModel.stock = Object.assign(this.stock)
  },
  methods: {
    // 打开库存选择
    openModel (row, index) {
      this.nowSelStockId = index
      this.dialogVisible = true
      this.stock = Object.assign({
        stock: this.skuList.data[this.nowSelStockId].stock,
        max_stock: this.skuList.data[this.nowSelStockId].max_stock,
        is_refresh: this.skuList.data[this.nowSelStockId].is_refresh,
        is_ks_stock: this.skuList.data[this.nowSelStockId].is_ks_stock
      })
    },
    // 选择的库存
    stockSave () {
      this.skuList.data[this.nowSelStockId].stock = this.stock.stock
      this.skuList.data[this.nowSelStockId].is_ks_stock = this.stock.is_ks_stock
      this.skuList.data[this.nowSelStockId].stock = this.stock.stock
      this.skuList.data[this.nowSelStockId].max_stock = this.stock.max_stock
      this.skuList.data[this.nowSelStockId].is_refresh = this.stock.is_refresh
      this.skuList.data[this.nowSelStockId].is_ks_stock = this.stock.is_ks_stock
      this.dialogVisible = false
    },
    //
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }
        // 是否保存sku了？
        for (let i in this.skuList.data) {
          if (this.skuList.data[i].isSet) {
            this.$message.warning('请先保存选项在进行操作!')
            return
          }
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    },
    // 删除选卡项
    deleteSku (row, index) {
      this.$deleteDialog(`${row.sku_name}`, async action => {
        if (action === 'confirm') {
          this.loading = true
          this.skuList.data.splice(index, 1)
          row.isSet = !row.isSet
          // this.$message({
          //   type: 'success',
          //   message: '删除成功'
          // })
          this.loading = false
        }
      })
    },
    // 添加按钮
    addMasterSku () {
      for (let i of this.skuList.data) {
        if (i.isSet) return this.$message.warning('请先保存当前编辑项')
      }
      let j = {
        sku_name: '',
        sku_price: '',
        is_ks_stock: true,
        max_stock: 0,
        stock: 0,
        sort: 1,
        is_refresh: false,
        isSet: true,
        id: 0,
        index: 0
      }
      this.skuList.data.push(j)
    },
    // 包存修改
    pwdChange (row, index, cg, close = false) {
      let that = this
      // 是否是取消操作
      if (!cg) {
        row.isSet = !row.isSet
        return
      }
      // 提交数据
      if (row.isSet) {
        (function () {
          let data = JSON.parse(JSON.stringify(that.skuList.data[index]))
          for (let k in data) {
            that.skuList.sel[index][k] = data[k]
          }
          that.formModel.skuData = that.skuList
          row.isSet = false
        })()
      } else {
        if (!that.skuList.sel[index]) {
          that.skuList.sel[index] = JSON.parse(JSON.stringify(row))
        }
        row.isSet = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table-add-row {
    width: 100%;
    border: 1px dashed #c1c1cd;
    margin-bottom: 20px;
    background: #fff;
}
.stock-box {
  padding:0 40px;
  .stock-item{
    margin-bottom: 20px;
  }
}
</style>
