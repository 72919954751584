<template>
    <div class="box">
        <div class="box-header">
            <h4>{{ pageTitle }}</h4>
        </div>
        <flavor-form
            v-if="flavor"
            :submit-text="pageTitle"
            :flavor="flavor"
            :is-edit="false"
            @on-submit="createFlavor"
        >
        </flavor-form>
        <placeholder-form v-else></placeholder-form>
    </div>
</template>

<script>
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flavorForm from './components/_EditForm'
import flatry from '@admin/utils/flatry'
import FlavorService from '@admin/services/FlavorService'

export default {
  components: { flavorForm, PlaceholderForm },
  data () {
    return {
      pageTitle: '新建规格',
      flavor: {
        flavorName: '',
        isRequired: false,
        isSales: false,
        choiceMode: false,
        sku: []
      }
    }
  },

  methods: {
    async createFlavor (flavor, success, callback) {
      const { data } = await flatry(FlavorService.create(flavor))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/flavor/update', query: { id: data.data } })
        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>
</style>
